import { Box, Typography, useTheme } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

export interface LegendPoint {
  /** description of the matrix point */
  label: string;
  /** the color of the circle */
  circleColor?: string;
  /** the text in the circle */
  circleText?: string;
}

export const LegendPoint: PimoReactComponent<LegendPoint> = ({
  label,
  circleColor = "#2f8ee7",
  circleText = "#",
}) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          backgroundColor: circleColor,
          margin: theme.spacing(0.2),
          border: "solid",
          width: `30px`,
          height: `30px`,
          maxHeight: "80px",
          maxWidth: "80px",
          fontSize: "1.5rem",
          lineHeight: `25px`,
          borderRadius: "100%",
          textAlign: "center",
          verticalAlign: "middle",
          color: "white",
        }}
      >
        <Typography
          fontWeight={500}
          fontSize={22}
          lineHeight={"inherit"}
          ml={-0.3}
        >
          {circleText}
        </Typography>
      </Box>
      <Typography fontWeight={400} fontSize={18} ml={-2}>
        {label}
      </Typography>
    </>
  );
};
