import ExcelJS from "exceljs";
import type {
  CommonRatingValue,
  ControlEnvRatingValue,
  FinancialRatingValue,
  ProbabilityRatingValue,
  ReputationalRatingValue,
} from "tracy-types";

type GenerateShortSummaryArguments = {
  controlEnvRating: ControlEnvRatingValue;
  financialRating: FinancialRatingValue;
  name: string;
  probabilityRating: ProbabilityRatingValue;
  reputationalRating: ReputationalRatingValue;
  representingSource: string[];
  targetScore: CommonRatingValue;
  year: number;
};

export async function generateShortSummary({
  controlEnvRating,
  financialRating,
  name,
  probabilityRating,
  reputationalRating,
  representingSource,
  targetScore,
  year,
}: GenerateShortSummaryArguments) {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Short Summary");
  const styles = { font: { bold: true } };

  /** Short Summary */
  sheet.getCell("A1").value = "Short Summary";
  sheet.getCell("A1").style = styles;
  sheet.getCell("A2").value =
    "The short summary report provides information, which can be used for the top risk reporting in ORGS.";
  sheet.getCell("A3").value =
    "Please note: This is a recommendation for the mandatory fields in ORGS. That means you can adapt it when you are filling out the fields in ORGS.";

  /** General Information */
  sheet.getCell("A5").value = "General Information";
  sheet.getCell("A5").style = styles;
  sheet.getCell("A6").value = "OE Name";
  sheet.getCell("B6").value = name;
  sheet.getCell("A7").value = "Year of the TRACY assessment";
  sheet.getCell("B7").value = year;

  /** Table Headers */
  sheet.getCell("A9").value = "Name of Field in ORGS";
  sheet.getCell("A9").style = styles;
  sheet.getCell("B9").value =
    "Recommendation to fill the field with the following";
  sheet.getCell("B9").style = styles;
  sheet.getCell("C9").value = "Explanation";
  sheet.getCell("C9").style = styles;

  /** Table Fields */
  sheet.getCell("A10").value = "Name";
  sheet.getCell("B10").value = "CYBER RISK";
  sheet.getCell("A11").value = "Risk Title / Risk Code";
  sheet.getCell("B11").value = "CYBER RISK";
  sheet.getCell("A12").value = "Risk Assessment Type";
  sheet.getCell("B12").value = "TRA";
  sheet.getCell("A13").value = "Active Risk";
  sheet.getCell("B13").value = "YES";
  sheet.getCell("A14").value = "Risk Owner";
  sheet.getCell("B14").value =
    "Has to be filled out in ORGS according to your OE";
  sheet.getCell("A15").value = "Risk Description";
  sheet.getCell("B15").value = [
    "Within the top risk assessment of cyber risk the predefined mandatory scenarios were assessed. The representing source of the ratings was: ",
    [...new Set(representingSource)].join(", "),
  ].join("\n");
  sheet.getCell("A16").value = "NFRM - Risk Category";
  sheet.getCell("B16").value = "Operational";
  sheet.getCell("A17").value = "NFRM - Risk Domain";
  sheet.getCell("B17").value = "Technology & Architecture";
  sheet.getCell("A18").value = "NFRM - Risk Vector";
  sheet.getCell("B18").value = "R2";
  sheet.getCell("C18").value =
    "Recommended NFRM risk vector R2 (Run Cyber resilient IT Business Applications) based on relevant mapped IT&IS controls to TRACY scenarios.";
  sheet.getCell("A19").value = "Risk Category";
  sheet.getCell("B19").value = "Operational Risk";
  sheet.getCell("A20").value = "Control Environment Effectinvess";
  sheet.getCell("B20").value =
    controlEnvRating != null ? controlEnvRating : "N/A";
  sheet.getCell("C20").value =
    "This value is from control environment effectiveness rating from table 2. Control Environment Effectiveness on your OE's TRACY report page";
  sheet.getCell("A21").value = "Control Environment";
  sheet.getCell("B21").value =
    "Has to be filled out in ORGS according to your OE";
  sheet.getCell("A22").value = "Assessment Comments";
  sheet.getCell("B22").value =
    "Has to be filled out in ORGS according to your OE";
  sheet.getCell("A23").value = "Occurence Probability";
  sheet.getCell("B23").value =
    probabilityRating != null ? probabilityRating : "N/A";
  sheet.getCell("C23").value =
    "This value is the overall probability rating from the summary table residual cyber risk on your OE's TRACY report page";
  sheet.getCell("A24").value = "Reputational Impact";
  sheet.getCell("B24").value =
    reputationalRating != null ? reputationalRating : "N/A";
  sheet.getCell("C24").value =
    "This value is the overall reputational impact rating from the summary table residual cyber risk on your OE's TRACY report page";
  sheet.getCell("A25").value = "Economic Impact";
  sheet.getCell("B25").value =
    financialRating != null ? financialRating : "N/A";
  sheet.getCell("C25").value =
    "This value is the overall financial impact rating from the summary table residual cyber risk on your OE's TRACY report page";
  sheet.getCell("A26").value = "Target Risk Score";
  sheet.getCell("B26").value = targetScore != null ? targetScore : "N/A";
  sheet.getCell("C26").value =
    "This value is the maximum of overall rating of the residual cyber risk from the summary table residual cyber risk on your OE's TRACY report page";

  const blob = new Blob([await workbook.xlsx.writeBuffer()], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const link = document.createElement("a");

  link.href = window.URL.createObjectURL(blob);
  link.download = "Short Summary.xlsx";
  link.click();
}
