import type { ButtonProps } from "@mui/material";
import { App, Plugin, Route } from "@pimo/pimo-app-builder";
import { GridLayout } from "@pimo/pimo-components";
import { generatePath, type Params } from "react-router-dom";
import type {
  EntityValidationResult,
  FE_Scenario,
  OERatingResponse,
  OEReportPage,
  ScenarioProgress,
  ScenariosFormValuesResponse,
} from "tracy-types";
import {
  calculateScenariosFormValuesProgress,
  isReadonlyForOEID,
  validateScenarioFormValuesRequest,
} from "tracy-utils";

import type { TracyAppState } from "../../app";
import { ScenariosActions } from "../../components/scenarios/scenarios-actions";
import { ScenariosHeader } from "../../components/scenarios/scenarios-header";
import { ScenariosLoader } from "../../components/scenarios/scenarios-loader";
import { ScenariosNavigation } from "../../components/scenarios/scenarios-navigation";
import {
  ScenariosSidebar,
  ScenariosSidebarEventPayload,
} from "../../components/scenarios/scenarios-sidebar";
import { ScenariosSidebarHeadline } from "../../components/scenarios/scenarios-sidebar-headline";
import { SCENARIO_FLOW_STEPS } from "../../components/scenarios/scenarios-steps";
import {
  type ScenarioFlowCategory,
  type ScenarioFlowStep,
  type ScenariosFormMethods,
  type ScenariosFormMountEventPayload,
  type ScenariosFormValues,
} from "../../components/scenarios/types";
import { APP_ROUTES } from "../../constants";
import { addScenario } from "../../helpers/add-scenario";
import { fetchOERatings } from "../../helpers/fetch/fetch-oe-ratings";
import { fetchOEReportPage } from "../../helpers/fetch/fetch-oe-report-page";
import { deleteScenario } from "../../helpers/fetch/fetch-scenario";
import {
  fetchScenariosFormValues,
  postScenariosFormValues,
  resetScenarioParameters,
} from "../../helpers/fetch/fetch-scenarios-form-values";
import { getLatestSteps } from "../../helpers/local-storage/get-latest-steps";
import { setLatestSteps } from "../../helpers/local-storage/set-latest-steps";
import { StepsDialogLayout } from "../../layouts/steps-dialog-layout";

export type ScenariosPluginState = {
  currentOERatings?: OERatingResponse;
  currentOEReportPage?: OEReportPage;
  currentScenario?: FE_Scenario;
  currentScenarioProgress?: ScenarioProgress;
  currentScenariosFormValues?: ScenariosFormValuesResponse;
  currentScenarioCategory?: ScenarioFlowCategory;
  currentScenarioStep?: ScenarioFlowStep;

  formMethods?: ScenariosFormMethods;
  formValues?: ScenariosFormValues;
  formValidationResult?: EntityValidationResult;

  isScenariosPluginLoading?: boolean;

  /** if set to `true`, ignore validation and allow users to jump
   * to the next step even with an invalid current step.
   *
   * this will be set when the user sees a validation error dialog
   * and either clicks "Ignore errors" (--> `true`) or "show errors"
   * (--> `false`)
   */
  ignoreValidation?: boolean;

  /**
   * controls whether or not to show the validation errors warning dialog
   */
  showValidationErrorsDialog?: boolean;

  lastAction?: "navigate" | "next" | "prev" | "save" | "save-and-close";
  lastActionPath?: string;
};

const SCENARIO_NAVIGATION_HEIGHT = 72;

export class ScenariosPlugin
  implements Plugin<TracyAppState, ScenariosPluginState, "oeId">
{
  app?: App<TracyAppState>;
  pattern = `${APP_ROUTES.oeScenarios}/*`;
  previousRoutePayload: unknown = null;
  private hasNewScenarioJustBeenCreated = false;

  constructor(public route: Route<"oeId", TracyAppState, GridLayout>) {}

  onRegister(app: App<TracyAppState>): void {
    this.app = app;

    const scenariosView = app.createView({
      name: "Flow",
      layout: new StepsDialogLayout({
        dialog: {
          open: true,
          onClose: () =>
            app.navigate(
              generatePath(APP_ROUTES.oeReportAssessment, {
                oeId: String(
                  app.getAppState().currentScenariosFormValues?.oe?.id ?? 1
                ),
              })
            ),
        },
        grid: {
          spacing: 0,
        },
        pattern: this.pattern,
        steps: [...SCENARIO_FLOW_STEPS],
      }),
    });

    const loadingScreen = scenariosView.addComponent({
      component: ScenariosLoader,
      layoutProps: {
        type: "loading",
      },
    });

    loadingScreen.mapState((state) => {
      return {
        isLoading: state.isScenariosPluginLoading,
      };
    });

    const header = scenariosView.addComponent({
      component: ScenariosHeader,
      layoutProps: {
        type: "title",
      },
    });

    header.mapState((state) => {
      const {
        currentScenario,
        currentScenarioCategory,
        currentScenarioProgress,
        currentScenariosFormValues,
      } = state;

      return {
        percentage: currentScenariosFormValues?.percentage ?? 0,
        title: [currentScenario?.name, currentScenarioCategory]
          .filter(Boolean)
          .join(" - "),
        status: currentScenarioProgress?.total ?? "open",
      };
    });

    const filter = scenariosView.addComponent({
      component: ScenariosSidebarHeadline,
      layoutProps: {
        sx: { height: `${SCENARIO_NAVIGATION_HEIGHT}px` },
        type: "content",
        xs: 4,
        md: 3,
        xl: 2,
      },
    });

    filter.mapState(() => ({}));

    const navigation = scenariosView.addComponent({
      component: ScenariosNavigation,
      layoutProps: {
        sx: { height: `${SCENARIO_NAVIGATION_HEIGHT}px` },
        type: "content",
        xs: 8,
        md: 9,
        xl: 10,
      },
    });

    navigation.mapState((state) => {
      const {
        currentScenarioStep,
        currentScenarioCategory,
        currentScenarioProgress,
      } = state;

      return {
        currentScenarioProgress,
        currentStep: currentScenarioStep,
        pattern: this.pattern,
        steps: SCENARIO_FLOW_STEPS.filter(
          (step) => step.category === currentScenarioCategory
        ),
      };
    });

    navigation.on("change", async (event) => {
      const step = event.payload?.step;

      if (!step) {
        return;
      }

      const state = app.getAppState();
      const path = generatePath(this.pattern, {
        oeId: String(state?.currentScenariosFormValues?.oe?.id ?? 1),
        scenarioId: String(state?.currentScenario?.id),
        "*": step.viewname,
      });

      this.app?.patchAppState({
        lastAction: "navigate",
        lastActionPath: path,
      });

      await this.navigate(path);
    });

    const sidebar = scenariosView.addComponent({
      component: ScenariosSidebar,
      layoutProps: {
        sx: { minHeight: `calc(100% - ${SCENARIO_NAVIGATION_HEIGHT}px)` },
        type: "content",
        xs: 4,
        md: 3,
        xl: 2,
      },
    });

    sidebar.mapState((state) => {
      const {
        currentOEReportPage,
        currentScenario,
        currentScenarioCategory,
        currentScenariosFormValues,
        isScenariosPluginLoading,
        permissions,
      } = state;

      const isLocked = currentOEReportPage?.oeStatus?.isLocked;
      const disabled =
        isLocked ||
        isReadonlyForOEID(permissions, currentOEReportPage?.oe?.id ?? 0);

      return {
        currentScenario,
        currentScenarioCategory,
        disabled,
        isLoading: isScenariosPluginLoading,
        scenarios:
          currentScenariosFormValues?.scenarios?.map(
            ({ scenario, progress }) => ({ ...scenario, progress })
          ) ?? [],
      };
    });

    sidebar.on("change", async (event) => {
      const { category, scenario } =
        event.payload ?? ({} as ScenariosSidebarEventPayload);

      if (!category || !scenario) {
        return;
      }

      const state = app.getAppState();
      const path = generatePath(this.pattern, {
        oeId: String(state?.currentScenariosFormValues?.oe?.id ?? 1),
        scenarioId: String(scenario.id),
        "*": SCENARIO_FLOW_STEPS.find((step) => step.category === category)
          ?.viewname,
      });

      this.app?.patchAppState({
        lastAction: "navigate",
        lastActionPath: path,
      });

      await this.navigate(path);
    });

    sidebar.on("add-scenario", () => {
      this.hasNewScenarioJustBeenCreated = true;
      void addScenario(app);
    });

    SCENARIO_FLOW_STEPS.forEach((step) => {
      const stepComponent = scenariosView.addComponent({
        component: step.component,
        layoutProps: {
          step: step.viewname,
          sx: { minHeight: `calc(100% - ${SCENARIO_NAVIGATION_HEIGHT}px)` },
          type: "content",
          xs: 8,
          md: 9,
          xl: 10,
        },
      });

      stepComponent.mapState((state) => {
        const {
          currentOERatings,
          currentOEReportPage,
          currentScenario,
          currentScenarioStep,
          formValues,
          formValidationResult,
          permissions,
          showValidationErrorsDialog,
        } = state;

        const isLocked = currentOEReportPage?.oeStatus?.isLocked;
        const disabled =
          isLocked ||
          isReadonlyForOEID(permissions, currentOEReportPage?.oe?.id ?? 0);

        return {
          oeRatings: currentOERatings!,
          oeReportPage: currentOEReportPage!,
          scenario: currentScenario!,
          step: currentScenarioStep!,
          disabled,
          formValues,
          validationResult: formValidationResult,
          showValidationErrorsDialog,
        };
      });

      stepComponent.on("form:submit", () => this.save());

      stepComponent.on("form:ignore-errors", () => {
        const state = app.getAppState();
        const lastAction = state.lastAction;
        const lastActionPath = state.lastActionPath;

        app.patchAppState({
          ignoreValidation: true,
          isScenariosPluginLoading: false,
          formValidationResult: undefined,
          lastAction: undefined,
          lastActionPath: undefined,
          showValidationErrorsDialog: false,
        });

        switch (lastAction) {
          case "navigate":
            void this.navigate(lastActionPath ?? "");
            break;
          case "next":
            void this.next();
            break;
          case "prev":
            void this.prev();
            break;
          case "save":
            void this.save();
            break;
          case "save-and-close":
            void this.saveAndClose();
            break;
        }
      });

      stepComponent.on("form:show-errors", () => {
        app.patchAppState({
          ignoreValidation: false,
          isScenariosPluginLoading: false,
          showValidationErrorsDialog: false,
        });
      });

      stepComponent.on("form:mount", (event) => {
        const formMethods = (
          event.payload as unknown as ScenariosFormMountEventPayload
        )?.formMethods;

        app.patchAppState({
          formMethods,
        });
      });

      stepComponent.on("form:unmount", () => {
        app.patchAppState({
          formMethods: undefined,
        });
      });

      stepComponent.on("parameters:reset", async () => {
        const state = app.getAppState();
        const oeId = state.currentScenariosFormValues?.oe?.id;
        const scenarioId = state.currentScenario?.id;
        const year = state.year;
        const step = state.currentScenarioStep;

        if (oeId == null || scenarioId == null || year == null || !step) {
          return;
        }

        await resetScenarioParameters({ oeId, scenarioId, year });

        void this.load({
          eventPayload: {},
          oeId: String(oeId),
          scenarioId: String(scenarioId),
          pathName: step.viewname,
        });
      });

      stepComponent.on("parameters:delete", async () => {
        const state = app.getAppState();
        const scenarioId = state.currentScenario?.id;
        const oeId = state.currentScenariosFormValues?.oe?.id;
        const year = state.year;

        if (!oeId || !scenarioId) {
          return;
        }

        await deleteScenario({ oeId, scenarioId });

        const oeReportPage = await fetchOEReportPage({
          id: oeId,
          year,
        });

        if (!oeReportPage) {
          return;
        }

        app.patchAppState({
          currentOEReportPage: oeReportPage,
        });

        app.navigate(
          generatePath(APP_ROUTES.oeReportAssessment, {
            oeId: String(oeId),
          })
        );
      });
    });

    const actions = scenariosView.addComponent({
      component: ScenariosActions,
      layoutProps: {
        type: "actions",
      },
    });

    actions.mapState(
      ({ currentOEReportPage, currentScenarioStep, permissions }) => {
        const currentStepIndex = SCENARIO_FLOW_STEPS.findIndex(
          (step) => step.name === currentScenarioStep?.name
        );
        const prevStep = SCENARIO_FLOW_STEPS?.[currentStepIndex - 1];
        const commonProps: ButtonProps = {
          variant: "contained",
          sx: {
            textTransform: "none",
          },
        };
        const isLastStep = currentStepIndex === SCENARIO_FLOW_STEPS.length - 1;
        const isLocked = currentOEReportPage?.oeStatus?.isLocked;

        return {
          cancel: {
            variant: "text",
            children: "Cancel",
          },
          close: {
            ...commonProps,
            children:
              isLocked ||
              isReadonlyForOEID(
                permissions,
                currentOEReportPage?.oe?.id ?? 0
              ) ||
              currentStepIndex === 4 ||
              currentStepIndex === 5
                ? "Close"
                : isLastStep
                  ? "Close and go to overview"
                  : "Save and close",
          },
          next: {
            ...commonProps,
            children:
              isLocked ||
              isReadonlyForOEID(
                permissions,
                currentOEReportPage?.oe?.id ?? 0
              ) ||
              currentStepIndex === 4 ||
              currentStepIndex === 5
                ? "Next step"
                : "Save and next step",
            style: isLastStep ? { display: "none" } : {},
          },
          prev: {
            ...commonProps,
            variant: "text",
            children:
              isLocked ||
              isReadonlyForOEID(
                permissions,
                currentOEReportPage?.oe?.id ?? 0
              ) ||
              currentStepIndex === 4 ||
              currentStepIndex === 5
                ? "Previous step"
                : "Save and previous step",
            disabled: !prevStep,
          },
        };
      }
    );

    actions.on("actions:cancel", () => {
      const { currentScenariosFormValues } = app.getAppState();

      app.navigate(
        generatePath(APP_ROUTES.oeReportAssessment, {
          oeId: String(currentScenariosFormValues?.oe?.id ?? 1),
        })
      );
    });

    actions.on("actions:close", () => {
      const params = new URLSearchParams(window.location.search);

      if (params.size) {
        void this.save();
      } else {
        void this.saveAndClose();
      }
    });

    actions.on("actions:next", () => {
      const params = new URLSearchParams(window.location.search);

      if (params.size) {
        void this.save();
      } else {
        void this.next();
      }
    });

    actions.on("actions:prev", () => {
      const params = new URLSearchParams(window.location.search);

      if (params.size) {
        void this.save();
      } else {
        void this.prev();
      }
    });

    const route = this.route.createChildRoute({
      path: "scenarios/:scenarioId/*",
      view: scenariosView,
    });

    route.on("load", (event) => {
      const params = event.payload?.parameters as unknown as Params<
        "oeId" | "scenarioId" | "*"
      >;

      if (!params.oeId || !params.scenarioId || !params["*"]) {
        return;
      }

      const state = this.app?.getAppState();
      const oeId = params.oeId;
      const scenarioId = params.scenarioId;
      const pathName = params["*"];
      const force = Number(params.scenarioId) !== state?.currentScenario?.id;
      const latestSteps = getLatestSteps();

      setLatestSteps({
        ...(latestSteps ?? {}),
        [oeId]: {
          ...(latestSteps ?? {})?.[oeId],
          latestStep: { scenarioID: scenarioId, step: pathName },
          scenarios: {
            ...(latestSteps ?? {})?.[oeId]?.scenarios,
            [scenarioId]: { step: pathName },
          },
        },
      });

      void this.load({
        oeId,
        scenarioId,
        pathName,
        eventPayload: event.payload,
        force,
      });
    });
  }

  private async load({
    oeId,
    scenarioId,
    pathName,
    eventPayload,
    force = false,
  }: {
    oeId: string;
    scenarioId: string;
    pathName: string;
    eventPayload: unknown;
    force?: boolean;
  }) {
    if (!this.app) {
      return;
    }
    const state = this.app.getAppState();
    const step = SCENARIO_FLOW_STEPS.find((step) => step.viewname === pathName);

    if (!step) {
      return;
    }

    this.app.patchAppState({
      isScenariosPluginLoading: true,
    });

    const scenariosFormValues =
      !state.currentScenariosFormValues ||
      this.hasNewScenarioJustBeenCreated ||
      force ||
      state.currentScenarioStep?.step !== step.step
        ? await fetchScenariosFormValues({
            id: +oeId,
            year: +state.year,
          })
        : state.currentScenariosFormValues;

    const scenario = scenariosFormValues?.scenarios?.find(
      ({ scenario }) => scenario.id === Number(scenarioId)
    );

    if (!scenario) {
      this.app.patchAppState({ ...state, isScenariosPluginLoading: false });
      return;
    }

    // We have one `patchAppState` call here an done later below. This is on purpose.
    // This `patchAppState` call happens before the time-consuming `fetch` calls
    // below and makes sure to update the navigation bar to display the next step
    // before backend fetching is done. This contributes to a more snappy UX.
    this.app.patchAppState({
      currentScenario: scenario?.scenario,
      currentScenarioCategory: step.category,
      currentScenarioStep: step,
      currentScenarioProgress: scenario?.progress,
      currentScenariosFormValues: scenariosFormValues,
      formValues: scenario?.formValues,
      isScenariosPluginLoading: false,
    });

    this.previousRoutePayload = eventPayload;

    const [oeReportPage, oeRatings] = await Promise.all([
      fetchOEReportPage({
        id: +oeId,
        year: +state.year,
      }),
      fetchOERatings({ id: +oeId, year: +state.year }),
    ]);

    if (!oeReportPage) {
      this.app.patchAppState({
        isScenariosPluginLoading: false,
      });
      return;
    }

    this.app.patchAppState({
      currentOERatings: oeRatings,
      currentOEReportPage: oeReportPage,
    });
  }

  private async navigate(path: string) {
    if (!this.app || path === window.location.pathname) {
      return;
    }

    this.app.patchAppState({
      isScenariosPluginLoading: true,
    });

    try {
      await this.submitScenariosFormValues();
      this.app.navigate(path);
    } catch (error) {
      console.error(error);
    }
  }

  private async next() {
    if (!this.app) {
      return;
    }

    this.app.patchAppState({
      lastAction: "next",
      isScenariosPluginLoading: true,
    });

    const { currentScenariosFormValues, currentScenario, currentScenarioStep } =
      this.app.getAppState();

    const currentStepIndex = SCENARIO_FLOW_STEPS.findIndex(
      (step) => step.name === currentScenarioStep?.name
    );

    const step = SCENARIO_FLOW_STEPS?.[currentStepIndex + 1];

    if (!step) {
      return;
    }

    await this.submitScenariosFormValues();

    this.app.navigate(
      generatePath(this.pattern, {
        oeId: String(currentScenariosFormValues?.oe?.id ?? 1),
        scenarioId: String(currentScenario?.id),
        "*": step.viewname,
      })
    );
  }

  private async prev() {
    if (!this.app) {
      return;
    }

    this.app.patchAppState({
      lastAction: "prev",
      isScenariosPluginLoading: true,
    });

    const { currentScenario, currentScenarioStep, currentScenariosFormValues } =
      this.app.getAppState();

    const currentStepIndex = SCENARIO_FLOW_STEPS.findIndex(
      (step) => step.name === currentScenarioStep?.name
    );
    const step = SCENARIO_FLOW_STEPS?.[currentStepIndex - 1];

    if (!step) {
      return;
    }

    await this.submitScenariosFormValues();

    this.app.navigate(
      generatePath(this.pattern, {
        oeId: String(currentScenariosFormValues?.oe?.id ?? 1),
        scenarioId: String(currentScenario?.id),
        "*": step.viewname,
      })
    );
  }

  private async save() {
    if (!this.app) {
      return;
    }

    this.app.patchAppState({
      lastAction: "save",
    });

    await this.submitScenariosFormValues();
    this.removeQueryParamsFromUrl();
  }

  private async saveAndClose() {
    if (!this.app) {
      return;
    }

    const { currentScenariosFormValues } = this.app.getAppState();

    this.app.patchAppState({
      lastAction: "save-and-close",
      snackbar: {
        message: "All changes have been saved.",
        severity: "success",
        open: true,
        type: "snackbar",
      },
    });

    await this.submitScenariosFormValues();

    const oeId = currentScenariosFormValues?.oe?.id;
    const state = this.app.getAppState();

    if (!oeId) {
      return;
    }

    const [oeReportPage, oeRatings] = await Promise.all([
      fetchOEReportPage({
        id: +oeId,
        year: +state.year,
      }),
      fetchOERatings({ id: +oeId, year: +state.year }),
    ]);

    if (!oeReportPage) {
      this.app.patchAppState({
        isScenariosPluginLoading: false,
      });
      return;
    }

    this.app.patchAppState({
      currentOERatings: oeRatings,
      currentOEReportPage: oeReportPage,
    });

    this.app.navigate(
      generatePath(APP_ROUTES.oeReportAssessment, {
        oeId: String(currentScenariosFormValues?.oe?.id ?? 1),
      })
    );
  }

  /**
   * Validates and submits the form values for the current scenario step.
   * Exits early if the oe status is locked or the user is readonly.
   */
  private async submitScenariosFormValues() {
    if (!this.app) {
      return;
    }

    const { currentOEReportPage, permissions } = this.app.getAppState();
    const isLocked = currentOEReportPage?.oeStatus?.isLocked;
    const disabled =
      isLocked ||
      isReadonlyForOEID(permissions, currentOEReportPage?.oe?.id ?? 0);
    if (disabled) {
      return;
    }

    this.validateCurrentStep();

    const {
      currentScenario,
      currentScenariosFormValues,
      currentScenarioStep,
      formMethods,
      year,
    } = this.app.getAppState();

    if (
      !currentScenariosFormValues?.oe?.id ||
      !currentScenario?.id ||
      !currentScenarioStep ||
      !formMethods ||
      !year
    ) {
      return;
    }

    const {
      getValues,
      formState: { defaultValues },
    } = formMethods;

    const formValues = getValues();
    const hasChanges =
      JSON.stringify(defaultValues) !== JSON.stringify(formValues);
    const progress = calculateScenariosFormValuesProgress(
      formValues,
      currentScenario
    );

    this.app.patchAppState({
      currentScenarioProgress: progress,
    });

    this.updateCurrentScenarioFormValuesInOverallFormValues();
    this.removeQueryParamsFromUrl();

    // send actual data to backend in background
    let newFormValues: ScenariosFormValuesResponse | undefined = undefined;

    if (hasChanges) {
      newFormValues = await postScenariosFormValues({
        data: formValues,
        oeId: currentScenariosFormValues.oe.id,
        scenarioId: currentScenario.id,
        year,
        step: currentScenarioStep.step,
        riskType: currentScenarioStep.riskType,
        ignoreValidation: !!this.app.getAppState().ignoreValidation,
      });
    }

    if (!this.app) {
      return;
    }

    this.app.patchAppState({
      formValidationResult: undefined,
      ignoreValidation: false,
      showValidationErrorsDialog: false,
    });

    if (newFormValues) {
      const scenario = newFormValues?.scenarios?.find(
        ({ scenario }) => scenario.id === currentScenario?.id
      );

      formMethods.reset(formValues);

      this.app.patchAppState({
        currentScenario: scenario?.scenario,
        currentScenariosFormValues: newFormValues,
        currentScenarioProgress: scenario?.progress,
        snackbar: {
          message: "All changes have been saved.",
          severity: "success",
          open: true,
          type: "snackbar",
        },
      });

      void this.loadOEReportPage();
      void this.loadOERating();
    }
  }

  private validateCurrentStep() {
    if (!this.app) {
      return;
    }

    const {
      currentOEReportPage,
      currentScenarioStep,
      currentScenario,
      formMethods,
      ignoreValidation,
      permissions,
    } = this.app.getAppState();

    const isLocked = currentOEReportPage?.oeStatus?.isLocked;

    if (
      !formMethods ||
      !currentScenario ||
      !currentScenarioStep ||
      isLocked ||
      isReadonlyForOEID(permissions, currentOEReportPage?.oe?.id ?? 0)
    ) {
      return;
    }

    try {
      if (!ignoreValidation) {
        const formValues = formMethods.getValues();

        validateScenarioFormValuesRequest(
          {
            ...formValues,
            step: currentScenarioStep.step,
            riskType: currentScenarioStep.riskType,
          },
          currentScenario,
          currentScenario.defaultScenario
        );
      }
    } catch (error) {
      this.app.patchAppState({
        formValidationResult: error as EntityValidationResult,
        showValidationErrorsDialog: true,
      });
      throw error;
    }
  }

  /**
   * This method takes the current scenario's `formValues` and stores them inside
   * `currentScenariosFormValues`.
   *
   * We do this because when loading a new step, the scenario's `formValues` will
   * be loaded from `currentScenariosFormValues`.
   *
   * As we retrieve the updated form values from the backend asynchronously and
   * therefore possibly after rendering, or even don't fetch them after an update,
   * we need to make sure the data is updated in the state independently
   * of the backend request.
   */
  private updateCurrentScenarioFormValuesInOverallFormValues() {
    if (!this.app) {
      return;
    }

    const {
      currentScenario,
      currentScenariosFormValues,
      currentScenarioProgress,
      formValues,
    } = this.app.getAppState();

    if (!currentScenario || !formValues) {
      return;
    }

    const scenarioIndex = currentScenariosFormValues?.scenarios.findIndex(
      (s) => s.scenario.id === currentScenario.id
    );
    if (
      !currentScenariosFormValues ||
      scenarioIndex == null ||
      scenarioIndex < 0
    ) {
      return;
    }
    currentScenariosFormValues.scenarios[scenarioIndex]!.formValues =
      formValues;

    if (currentScenarioProgress) {
      currentScenariosFormValues.scenarios[scenarioIndex]!.progress =
        currentScenarioProgress;
    }
  }

  /**
   * Removes query parameters from the URL and navigates back to the path without query parameters.
   *
   * This is useful for cases where a dialog is displayed because a query parameter is set, and
   * when a user closes the dialog, to hide it, that query parameter must go away.
   */
  private removeQueryParamsFromUrl() {
    if (!this.app) {
      return;
    }
    const { currentScenariosFormValues, currentScenario, currentScenarioStep } =
      this.app?.getAppState() ?? {};

    this.app.navigate(
      generatePath(this.pattern, {
        oeId: String(currentScenariosFormValues?.oe?.id ?? 1),
        scenarioId: String(currentScenario?.id),
        "*": currentScenarioStep?.viewname,
      })
    );
  }

  private async loadOEReportPage() {
    if (!this.app) {
      return;
    }

    const { currentScenariosFormValues, year } = this.app.getAppState();

    if (!currentScenariosFormValues?.oe?.id) {
      return;
    }

    const oeReportPage = await fetchOEReportPage({
      id: currentScenariosFormValues.oe.id,
      year,
    });

    this.app.patchAppState({
      currentOEReportPage: oeReportPage,
    });
  }

  private async loadOERating() {
    if (!this.app) {
      return;
    }

    const { currentScenariosFormValues, year } = this.app.getAppState();

    if (!currentScenariosFormValues?.oe?.id) {
      return;
    }

    const oeRatings = await fetchOERatings({
      id: currentScenariosFormValues.oe.id,
      year,
    });

    this.app.patchAppState({
      currentOERatings: oeRatings,
    });
  }
}
