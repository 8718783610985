export const OEReportProcessDescription2024 = `
The TRACY assessment consists of the following 4 sections, which should be completed consecutively:

1. General Parameters and Introduction
2. Control Environment Effectiveness
3. Cyber Risk Scenarios and Mitigations
4. Result

The below information supplement the detailed guidance displayed within each section of the application, including explanations as to which fields are mandatory and remarks what to consider while filling them.

### 1. General Parameters and Introduction

This section requires you to **provide basic information** relevant to your TRACY assessment. This includes the key OE contact for TRACY as well as the Operating Profit, which is the basis for all Financial Impact calculations. Moreover, you have to select a subversion of the scenario "Technical Vulnerability Exploitation". You can also see the progress of the assessment within this section.

### 2. Control Environment Effectiveness

After providing the general information, **assessing the Control Environment Effectiveness according to ASTRA is the next step**. The Control Environment Effectiveness is assessed on the highest level for your entire OE with respect to Cyber, based on your expert judgement.

### 3. Cyber Risk Scenarios and Mitigations

This section is the most important part of TRACY and consists of the **assessment of the cyber risk scenarios** as well as the **specification of corresponding mitigation actions in terms of a mitigation plan**. The assessment of cyber risk is based on predefined cyber risk scenarios provided by Allianz Group. The assessment of all scenarios predefined by Allianz Group are mandatory. In addition, you are encouraged to craft additional OE-specific cyber risk scenarios, if you identify threats or risks that cannot be covered by the mandatory cyber risk scenarios.

For each scenario, the Probability Rating, Financial Impact Rating and Reputational Impact Rating have to be assessed for the Residual Risk (i.e. the risk that exists under currently applied level of controls/ treatments). After providing additional, planned mitigation actions, the same assessment has to be done for the Target Risk (i.e. the risk remaining after mitigation).

### 4. Results

In the final section you will find a summary of the assessment results on scenario and OE level. If one cyber risk scenario is rated as Top Risk, then Cyber is generally considered to be a Top Risk for your OE.

Here you also have the option **to overrule the Top Risk result for your OE** (for Residual Risk). For example, if Cyber Risk is not assessed as a Top Risk based on the results, you can overrule it and provide an explanation. Beyond overruling, you may also include your **expert judgment comment for the entire assessment**. Especially, if Cyber is not assessed as a Top Risk and also not overruled, please include additional details clarifying your specific case as well as the information if Cyber is considered or included as subset in one of the other Top Risks.

After submitting the results, you can download a **summary as a recommendation for ORGS** by clicking on the "TRACY SHORT SUMMARY (ORGS)" button. **Please note**: This is a recommendation for the mandatory fields in ORGS, which can be entered manually in ORGS. This means that you can adjust it when you fill in the fields in ORGS.

**Please also note**: Once you have submitted the results, the edit functionality is locked. Please contact [isrm@allianz.com](mailto:isrm@allianz.com) if you would like to unlock it and edit the assessment.

`;

export const OEReportProcessDescription2025 = `
The TRACY assessment consists of the following 4 sections, which should be completed consecutively:

1. General Settings
2. Control Environment Effect
3. Cyber Risk Scenarios and Mitigations
4. Summary & Expert Judgement
5. 1 in 20 years probability

The below information supplement the detailed guidance displayed within each section of the application, including explanations as to which fields are mandatory and remarks what to consider while filling them.

### 1. General Settings

This section requires you to **provide basic information** relevant to your TRACY assessment. This includes the key OE contact for TRACY as well as the Operating Profit, which is the basis for all Financial Impact calculations. You can also see the progress of the assessment within this section.

### 2. Control Environment Effect

After providing the general information, **assessing the Control Environment Effectiveness according to ASTRA is the next step**. The Control Environment Effectiveness is assessed on the highest level for your entire OE with respect to Cyber, based on your expert judgement.

### 3. Cyber Risk Scenarios & Mitigations

This section is the most important part of TRACY and consists of the **assessment of the cyber risk scenarios** as well as the **specification of corresponding mitigation actions in terms of a mitigation plan**. The assessment of cyber risk is based on predefined cyber risk scenarios provided by Allianz Group. The assessment of all scenarios predefined by Allianz Group are mandatory. In addition, you are encouraged to craft additional OE-specific cyber risk scenarios, if you identify threats or risks that cannot be covered by the mandatory cyber risk scenarios.

For each scenario, the Probability Rating, Financial Impact Rating and Reputational Impact Rating have to be assessed for the Residual Risk (i.e. the risk that exists under currently applied level of controls/ treatments). After providing additional, planned mitigation actions, the same assessment has to be done for the Target Risk (i.e. the risk remaining after mitigation).

### 4. Summary & Expert Judgement

In the final section you will find a summary of the assessment results on scenario and OE level. If one cyber risk scenario is rated as Top Risk, then Cyber is generally considered to be a Top Risk for your OE.

Here you also have the option **to overrule the Top Risk result for your OE** (for Residual Risk). For example, if Cyber Risk is not assessed as a Top Risk based on the results, you can overrule it and provide an explanation. Beyond overruling, you may also include your **expert judgment comment for the entire assessment**. Especially, if Cyber is not assessed as a Top Risk and also not overruled, please include additional details clarifying your specific case as well as the information if Cyber is considered or included as subset in one of the other Top Risks.

After submitting the results, you can download a **summary as a recommendation for ORGS** by clicking on the "TRACY SHORT SUMMARY (ORGS)" button. **Please note**: This is a recommendation for the mandatory fields in ORGS, which can be entered manually in ORGS. This means that you can adjust it when you fill in the fields in ORGS.

**Please also note**: Once you have submitted the results, the edit functionality is locked. Please contact [isrm@allianz.com](mailto:isrm@allianz.com) if you would like to unlock it and edit the assessment.

### 5. 1 in 20 years probability

In general, we consider two types of probabilities when evaluating cyber risks:

- **Occurrence Probability**: The likelihood of a given scenario taking place.
- **Loss Probability**: The chance that, should the scenario occur, the resultant loss falls within a certain range.


In step one of TRACY, you assessed the occurrence probability. Subsequently, in steps two and three, you evaluated the financial and non-financial impact ratings assuming the scenario materializes, without specifying the loss probability. We are now seeking to refine these impact ratings by establishing a fixed loss probability of 1 in 20 years. This adjustment aims to assess the impact rating of a loss arising from a severe loss scenario occurring once in 20 years.
`;
