import {
  Alert,
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import type { ProbabilityRatingValue } from "tracy-types";
import { getColorSchemeAndTextForProbabilityRatingBadge } from "tracy-utils";

import { RATING_LABEL } from "../../../../config/rating-label";
import { DISABLED_INPUT_STYLES } from "../../../../constants";
import { getErrorMessage } from "../../../../helpers/validation/get-error-message";
import { hasError } from "../../../../helpers/validation/has-error";
import { ScenariosFormComponent, ScenariosFormValues } from "../../types";

const RATING_LABELS_WITH_PERCENTAGE = {
  1: "Rare (< 10 %)",
  2: "Unlikely (10 - 20 %)",
  3: "Moderate (20 - 50 %)",
  4: "Likely (50 - 90 %)",
  5: "Almost Certain (> 90 %)",
} as const;

const WORSE_RATING_WARNING =
  "The entered target risk rating is worse than the corresponding residual risk rating. Please review your assessment or provide an explanation in the comment field.";

const DISABLED_RATING_WARNING =
  "As there are no mitigation actions in place for this scenario, you cannot select some of the better ratings.";

export const ProbabilityRatingOfCyberRiskForm: ScenariosFormComponent<{
  riskType: "current" | "residual";
}> = ({
  disabled,
  formValues,
  riskType,
  scenario: { id, mitigations },
  validationResult,
}) => {
  const probabilityRatingFromAppState = formValues?.probabilityRating;

  const { register, setValue, watch, getValues } =
    useFormContext<ScenariosFormValues>();

  const probabilityRating = watch(`probabilityRating.${riskType}.rating`);
  const probabilityExplanation = watch(
    `probabilityRating.${riskType}.explanation`
  );
  const probabilityColorAndText =
    getColorSchemeAndTextForProbabilityRatingBadge(
      +probabilityRating as ProbabilityRatingValue
    );
  const currentProbabilityRating = getValues(
    `probabilityRating.current.rating`
  );
  const residualProbabilityRating = getValues(
    `probabilityRating.residual.rating`
  );
  const hasMitigations = mitigations.some(({ scenarios }) =>
    scenarios?.some((scenario) => scenario.id === id)
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        justifyContent: "space-between",
        height: "100%",
        minHeight: "100%",
      }}
    >
      <Box>
        {riskType === "residual" &&
          currentProbabilityRating < residualProbabilityRating && (
            <Alert sx={{ color: "gray" }} severity="warning">
              {WORSE_RATING_WARNING}
            </Alert>
          )}
        {riskType === "residual" &&
          !hasMitigations &&
          currentProbabilityRating > 1 && (
            <Alert sx={{ color: "gray" }} severity="warning">
              {DISABLED_RATING_WARNING}
            </Alert>
          )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            m: 4,
            mr: 6,
          }}
        >
          <Box
            sx={{
              flex: "0 0 50%",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl
              fullWidth
              error={hasError(
                `probabilityRating.${riskType}.rating`,
                validationResult
              )}
            >
              <InputLabel>Probability Rating</InputLabel>
              <Select
                disabled={disabled}
                inputProps={register(`probabilityRating.${riskType}.rating`)}
                label="Probability Rating"
                sx={
                  disabled
                    ? { ...DISABLED_INPUT_STYLES, position: "relative" }
                    : {}
                }
                value={probabilityRating ?? ""}
                variant="outlined"
              >
                {Object.entries(RATING_LABEL["ProbabilityRating"]).map(
                  ([key]) => {
                    const disabled =
                      riskType === "residual" &&
                      !hasMitigations &&
                      currentProbabilityRating > +key;
                    const label =
                      RATING_LABELS_WITH_PERCENTAGE[
                        key as unknown as keyof typeof RATING_LABELS_WITH_PERCENTAGE
                      ];

                    return (
                      <MenuItem disabled={disabled} key={key} value={+key}>
                        {label}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
              {hasError(
                `probabilityRating.${riskType}.rating`,
                validationResult
              ) && (
                <FormHelperText>
                  {getErrorMessage(
                    `probabilityRating.${riskType}.rating`,
                    validationResult
                  )}
                </FormHelperText>
              )}
            </FormControl>
            {riskType === "residual" && (
              <Typography sx={{ color: "#979797" }}>
                Residual Risk:{" "}
                {probabilityRatingFromAppState?.current?.rating
                  ? RATING_LABEL["ProbabilityRating"][
                      probabilityRatingFromAppState.current.rating
                    ]
                  : "N/A"}
              </Typography>
            )}
          </Box>
          <Box sx={{ flex: "0 0 50%", display: "flex" }}>
            <FormControl
              fullWidth
              error={hasError(
                `probabilityRating.${riskType}.explanation`,
                validationResult
              )}
            >
              <TextField
                disabled={disabled}
                inputProps={register(
                  `probabilityRating.${riskType}.explanation`
                )}
                label="Explanation of Rating"
                placeholder="Please provide a short description why you selected the Probability Rating"
                multiline
                rows={4}
                error={hasError(
                  `probabilityRating.${riskType}.explanation`,
                  validationResult
                )}
                onChange={(e) =>
                  setValue(
                    `probabilityRating.${riskType}.explanation`,
                    e.target.value
                  )
                }
                sx={disabled ? DISABLED_INPUT_STYLES : {}}
                value={probabilityExplanation ?? ""}
              />
              {hasError(
                `probabilityRating.${riskType}.explanation`,
                validationResult
              ) && (
                <FormHelperText>
                  {getErrorMessage(
                    `probabilityRating.${riskType}.explanation`,
                    validationResult
                  )}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          borderTop: "1px solid lightgrey",
          display: "flex",
          flexDirection: "row",
          gap: 4,
          px: 4,
          py: 2,
          pr: 6,
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>Probability Rating</Typography>
        <Chip
          label={probabilityColorAndText.text}
          sx={{
            background: probabilityColorAndText.colorScheme.chipColor,
            color: probabilityColorAndText.colorScheme.textColor,
            height: 28,
            px: 1,
          }}
        />
      </Box>
    </Box>
  );
};
