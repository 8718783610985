/**
 * Extracts the report ID from a given pathname.
 *
 * Splits the pathname into segments and searches for the segment "oe-reports".
 * If found, returns the segment following "oe-reports" as the report ID.
 * If not found, returns null.
 *
 * @param pathname - The pathname string from which to extract the report ID.
 * @returns The report ID if found, otherwise null.
 */

export function getReportIdFromPath(pathname: string) {
  const pathSegments = pathname.split("/").filter(Boolean);
  const reportIndex = pathSegments.indexOf("oe-reports");
  return reportIndex !== -1 ? pathSegments[reportIndex + 1] : null;
}
