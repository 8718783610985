import { GlobalSettings } from "tracy-types";

import { STRAPI_URL } from "../../env";

export async function fetchGlobalSettings(): Promise<
  GlobalSettings | undefined
> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff/global-setting`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as GlobalSettings;
  } catch {
    return;
  }
}
export async function updateGlobalSetting(
  payload: GlobalSettings
): Promise<Response | undefined> {
  try {
    const body = {
      lockGroupDashboard2024: payload.lockGroupDashboard2024,
      lockGroupDashboard2025: payload.lockGroupDashboard2025,
    };
    return await fetch(`${STRAPI_URL}/api/bff/global-setting`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(body),
    });
  } catch {
    return;
  }
}
