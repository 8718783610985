import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  Paper,
  Switch,
  TableContainer,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { useState } from "react";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import {
  EntityValidationResult,
  FE_OEStatus,
  OEStatus,
  UpdateStatus,
} from "tracy-types";

import { getErrorMessage } from "../../helpers/validation/get-error-message";
import { hasError } from "../../helpers/validation/has-error";
import { InfoPopup } from "../info-popup/info-popup";
import { TracyStatusIndicator } from "../tracy-status-indicator";
import { AssessmentTable, AssessmentTableEntries } from "./assessment-table";
import { ConfirmationDialogBox } from "./edit/confirmation-dialog-box";
import SummaryTableRiskCard from "./summary-table-risk";

type ScenarioOverviewProps = {
  currentCyberRiskAssessment: AssessmentTableEntries;
  residualCyberRiskAssessment: AssessmentTableEntries;
  currentRiskSummaryTableEntries?: {
    label: string;
    text: string;
    colorScheme?: { chipColor: string; textColor: string };
  }[];
  residualRiskSummaryTableEntries?: {
    label: string;
    text: string;
    colorScheme?: { chipColor: string; textColor: string };
  }[];
  oeStatus?: FE_OEStatus;
  formValidationResult?: EntityValidationResult;
  status: UpdateStatus;
};
export const ScenarioOverview: PimoReactComponent<
  ScenarioOverviewProps,
  "submit" | "cancel" | "go-back",
  OEStatus
> = ({
  currentCyberRiskAssessment,
  residualCyberRiskAssessment,
  currentRiskSummaryTableEntries,
  residualRiskSummaryTableEntries,
  oeStatus,
  fireEvent,
  formValidationResult,
  status,
}) => {
  const theme = useTheme();

  const { handleSubmit, register, control } = useForm<OEStatus>({
    defaultValues: oeStatus,
  });

  const [isOverruling, setIsOverruling] = useState(oeStatus?.overruledTopRisk);

  const [isComfirmationDialogOpen, setIsComfirmationDialogOpen] =
    useState(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState(
    !(oeStatus?.overruledTopRiskComment || oeStatus?.expertJudgment)
  );

  const onSubmit: SubmitHandler<OEStatus> = (data) => {
    fireEvent?.("submit", data);
  };

  const onCancel = () => {
    setIsComfirmationDialogOpen(true);
  };

  return (
    <>
      <ConfirmationDialogBox
        open={isComfirmationDialogOpen}
        onContinue={() => {
          setIsComfirmationDialogOpen(false);
        }}
        onClose={() => {
          setIsComfirmationDialogOpen(false);
          fireEvent?.("cancel");
        }}
      />
      <Dialog
        open={true}
        fullScreen
        sx={{ m: "1%" }}
        onClose={() => setIsComfirmationDialogOpen(true)}
        PaperProps={{ sx: { borderRadius: 5 } }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "12px",
          }}
        >
          <DialogTitle sx={{ fontWeight: 500, borderRadius: "12px" }}>
            Overview
          </DialogTitle>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              gap: 2,
              p: 2,
            }}
          >
            <Typography>Overall Update </Typography>
            <TracyStatusIndicator updateStatus={status} />
          </Box>
        </Box>
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
          }}
        />
        <InfoPopup
          images={["result.png"]}
          shortText="Review the summary table and include your expert judgement. You also have the option to overrule the Top Risk Result for your OE (for Residual Risk)."
          longText={`As the assessment is done on scenario level, but the reporting in ORGS is on risk level, we need a
**summary**. This is done via selecting a representative source (i.e. one of the scenarios) out of the
driving sources (i.e. the ones having "Top Risk" equal to yes). The figure below displays the
approach, how to find the representative source. Please note: if there is no driving source, that
means none which leads to a top risk, only the representative source is selected.\n\n**Expert judgement:** Below to the summary table you also have the option to overrule the Top Risk
result for your OE (for Residual Risk). For example, if Cyber Risk is not assessed as a Top Risk based on
the results, you can overrule it and provide an explanation. Beyond overruling, you may also include
your expert judgment comment for the entire assessment. Especially, if Cyber is not assessed as a
Top Risk and also not overruled, please include additional details clarifying your specific case as well 
as the information if Cyber is considered or included as subset in one of the other Top Risks.`}
        />
        <Box
          sx={{
            backgroundColor: theme.palette.secondary.main,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            p: 1,
            overflow: "auto",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{ borderRadius: "12px", boxShadow: "none", overflow: "unset" }}
          >
            <Box sx={{ p: 2 }}>
              <Typography
                sx={{ flex: "1 1 100%", fontWeight: 500, fontSize: "16px" }}
                id="tableTitle"
                component="div"
              >
                Residual Cyber Risk Assesment
              </Typography>
            </Box>
            <AssessmentTable
              entries={currentCyberRiskAssessment}
              riskType="current"
            />
          </TableContainer>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: "12px", boxShadow: "none", overflow: "unset" }}
          >
            <Box sx={{ p: 2 }}>
              <Typography
                sx={{ flex: "1 1 100%", fontWeight: 500, fontSize: "16px" }}
                id="tableTitle"
                variant="h5"
                component="div"
              >
                Residual Cyber Risk Assesment
              </Typography>
            </Box>
            <AssessmentTable
              entries={residualCyberRiskAssessment}
              riskType="residual"
            />
          </TableContainer>
          <Box
            sx={{
              backgroundColor: theme.palette.secondary.main,
              gap: 1,
              display: "flex",
              width: "100%",
            }}
          >
            <SummaryTableRiskCard
              title={"Summary Table Residual Cyber Risk"}
              cardProps={{ sx: { width: "50%" } }}
              entries={currentRiskSummaryTableEntries ?? []}
            />

            <SummaryTableRiskCard
              title={"Summary Table Residual Cyber Risk"}
              cardProps={{ sx: { width: "50%" } }}
              entries={residualRiskSummaryTableEntries ?? []}
            />
          </Box>
          <form
            id="edit-dialog-form"
            onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Box
                component={Paper}
                sx={{
                  width: "50%",
                  borderRadius: "0px",
                  borderTopLeftRadius: "12px",
                  borderBottomLeftRadius: "12px",
                  boxShadow: "none",
                  p: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ p: 1, fontWeight: 500, fontSize: "14px" }}>
                    Do you want to overrule Top Risk Result (for Residual
                    Assessment)?
                  </Typography>
                  <Controller
                    name="overruledTopRisk"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl>
                        <Switch
                          checked={value}
                          onChange={(event) => {
                            onChange(event);
                            setIsOverruling(event.target.checked);
                          }}
                        />
                      </FormControl>
                    )}
                  />
                </Box>
                <FormControl fullWidth>
                  <TextField
                    disabled={!isOverruling}
                    multiline={true}
                    label="Please comment overruling Top Risk Rating "
                    rows={6}
                    error={hasError(
                      "overruledTopRiskComment",
                      formValidationResult
                    )}
                    onChange={() => setIsButtonDisabled(false)}
                    helperText={getErrorMessage(
                      "overruledTopRiskComment",
                      formValidationResult
                    )}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: grey[700],
                        backgroundColor: grey[100],
                      },
                    }}
                    inputProps={register("overruledTopRiskComment")}
                    InputProps={{
                      style: {
                        height: "100%",
                        borderRadius: "5px",
                      },
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                component={Paper}
                sx={{
                  width: "50%",
                  borderRadius: "0px",
                  borderTopRightRadius: "12px",
                  borderBottomRightRadius: "12px",
                  boxShadow: "none",
                  p: 1,
                }}
              >
                <Typography sx={{ p: 1, fontWeight: 500, fontSize: "14px" }}>
                  Expert Judgement Comment (for Current and Residual Assessment)
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    multiline={true}
                    label="Please comment on the Judgement "
                    rows={7.5}
                    inputProps={register("expertJudgment")}
                    error={hasError("expertJudgment", formValidationResult)}
                    helperText={getErrorMessage(
                      "expertJudgment",
                      formValidationResult
                    )}
                    onChange={() => setIsButtonDisabled(false)}
                    sx={{ borderRadius: "12px" }}
                    InputProps={{
                      style: {
                        height: "100%",
                        borderRadius: "5px",
                      },
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
          </form>
        </Box>

        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 2,
            ml: 3,
            mr: 3,
            mb: 2,
            gap: 2,
          }}
        >
          <Button
            onClick={() => fireEvent?.("go-back")}
            sx={{ textTransform: "none" }}
            startIcon={<ArrowBack />}
          >
            Go Back to Scenario Reporting
          </Button>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button type="button" variant="text" onClick={() => onCancel()}>
              Cancel
            </Button>
            <Button
              sx={{ textTransform: "none" }}
              disabled={isButtonDisabled}
              type="submit"
              variant="contained"
              form="edit-dialog-form"
            >
              Save and Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
