import { STRAPI_URL } from "../../env";

type OESettings = {
  name: string;
  cimid: string;
  hideFromGroupDashboard: boolean;
  isTestOE?: boolean;
  hasShortcutAssessment: boolean;
  reopenTopRiskAssessment?: boolean;
  year?: number;
};

export function createOE(payload: OESettings) {
  return fetch(`${STRAPI_URL}/api/bff-create-oe`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(payload),
  });
}

export function updateOE(id: number, payload: OESettings) {
  return fetch(`${STRAPI_URL}/api/bff-update-oe/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(payload),
  });
}
export function deleteOE(id: number) {
  return fetch(`${STRAPI_URL}/api/bff-delete-oe/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });
}
