import {
  AssignmentOutlined,
  FileDownloadOutlined,
  InsertChartOutlinedTwoTone,
  Logout,
  SettingsOutlined,
  Speed,
} from "@mui/icons-material";
import { NotificationsPlugin } from "@pimo/notifications-plugin";
import { App, EventEmitter, Plugin } from "@pimo/pimo-app-builder";
import {
  DefaultOverlayNotificationPayload,
  HeaderProps,
} from "@pimo/pimo-components";
import { generatePath } from "react-router-dom";
import { isReadonly } from "tracy-utils";

import { TracyAppState } from "../app";
import { TracyOverlay } from "../components/tracy-overlay";
import { APP_ROUTES } from "../constants";
import { STRAPI_URL } from "../env";
import { fetchOEOverviewPage } from "../helpers/fetch/fetch-oe-overview-page";
import { fetchOEProbabilityRating } from "../helpers/fetch/fetch-oe-probability-rating";
import { fetchOERatings } from "../helpers/fetch/fetch-oe-ratings";
import { fetchOEReportPage } from "../helpers/fetch/fetch-oe-report-page";
import { fetchOEStatusComments } from "../helpers/fetch/fetch-oe-status-comments";
import { fetchPermissions } from "../helpers/fetch/fetch-permissions";
import { fetchScenariosFormValues } from "../helpers/fetch/fetch-scenarios-form-values";
import { fetchLogs } from "../helpers/fetch-logs";
import { getReportIdFromPath } from "../helpers/get-report-id";

export class TracyOverlayPlugin
  extends EventEmitter<"logout" | "overlay:settings-click">
  implements Plugin<TracyAppState>
{
  onRegister(app: App<TracyAppState>): void {
    const overlayView = app.createOverlayView({ name: "Overlay" });

    const overlay = overlayView.addComponent({
      component: TracyOverlay,
    });

    overlay.mapState(
      ({
        isSidebarOpen,
        userProfile,
        year,
        oes,
        banner,
        logs,
        permissions,
        snackbar,
      }) => ({
        hasSearchFunctionality: true,
        notifications: { banner },
        logs: logs ?? [],
        snackbar: snackbar,
        header: {
          logo: { big: "allianz.svg", small: "allianz-small.svg" },
          username: `${userProfile?.name ?? "Name n/a"} (${
            userProfile?.email ?? "Email n/a"
          }; groups: ${userProfile?.groups?.join(", ") ?? "n/a"})`,
          enableLogs: true,
          onLogsShow: () => {
            overlay.fireEvent("overlay:show-logs");
          },
          entries: [
            userProfile?.isAdmin &&
              !permissions?.some(isReadonly) && {
                icon: SettingsOutlined,
                onClick: () => this.fireEvent?.("overlay:settings-click"),
                text: "Settings",
              },
            {
              text: "Download Excel Report (Residual)",
              icon: FileDownloadOutlined,
              onClick: () =>
                window.open(`${STRAPI_URL}/api/bff/export/${year}/current`),
            },
            {
              text: "Download Excel Report (Target)",
              icon: FileDownloadOutlined,
              onClick: () =>
                window.open(`${STRAPI_URL}/api/bff/export/${year}/residual`),
            },
            {
              text: "Logout",
              onClick: () => {
                this.fireEvent("logout");
              },
              icon: Logout,
            },
          ].filter(Boolean) as HeaderProps["entries"],
        },
        isSidebarOpen,
        menuEntries: [
          {
            title: "Dashboard",
            link: APP_ROUTES.groupDashboard,
            level: 0,
            icon: Speed,
          },
          {
            title: "Status Overview",
            link: APP_ROUTES.oeOverview,
            level: 0,
            icon: AssignmentOutlined,
          },
          {
            title: "OE Assessment",
            level: 0,
            icon: InsertChartOutlinedTwoTone,
            items: [
              ...oes.map((oe) => ({
                active:
                  window.location.pathname.includes(
                    `/oe-reports/${oe.id ?? 0}/assessment`
                  ) ||
                  window.location.pathname.includes(
                    `/oe-reports/${oe.id ?? 0}/oe-dashboard`
                  ),

                level: 1,
                link: generatePath(APP_ROUTES.oeReportAssessment, {
                  oeId: oe.id?.toString() ?? "",
                }),
                title: oe.name,
              })),
            ].sort((entry1, entry2) =>
              entry1.title.localeCompare(entry2.title)
            ),
          },
        ],
        searchFunction: (items, query) => {
          if (!items?.length) {
            return [];
          }

          const lowerCaseQuery = query?.toLowerCase();

          return oes
            .filter(
              ({ name, oeClusters }) =>
                name?.toLocaleLowerCase()?.includes(lowerCaseQuery) ||
                oeClusters?.some(({ name }) =>
                  name?.toLocaleLowerCase()?.includes(lowerCaseQuery)
                )
            )
            .filter(({ isTestOE }) => {
              const isAdminWithEditAccess =
                userProfile?.isAdmin && !permissions?.some(isReadonly);
              const isNotTestOE = isTestOE === false || isTestOE === null;

              // Return true if the user is an admin with edit access, or the test condition is met
              return isAdminWithEditAccess || isNotTestOE;
            })

            .map(({ name, id }) => ({
              active:
                window.location.pathname.includes(
                  `/oe-reports/${id ?? 0}/assessment`
                ) ||
                window.location.pathname.includes(
                  `/oe-reports/${id ?? 0}/oe-dashboard`
                ),
              level: 1,
              link: generatePath(APP_ROUTES.oeReportAssessment, {
                oeId: id?.toString() ?? "",
              }),
              title: name,
            }));
        },
        year,
        userProfile,
      })
    );
    overlay.on("overlay:show-logs", async () => {
      const logs = await fetchLogs();
      app.patchAppState({ logs });
    });
    overlay.on("overlay:close-snackbar", () => {
      app.patchAppState({
        snackbar: undefined,
      });
    });

    overlay.on("year-selected", async ({ payload }) => {
      const yearSelectedPayload = payload as { year?: number };
      if (yearSelectedPayload?.year == null) {
        return;
      }
      // Check if the user is on an OE report page
      const pathname = window.location.pathname;
      const reportId = getReportIdFromPath(pathname);

      // If the user is on an OE report page, fetch the necessary data
      if (reportId) {
        const year = yearSelectedPayload.year;

        const [
          currentOEReportPage,
          currentOERatings,
          currentScenariosFormValues,
          currentOEStatusComments,
          currentOEProbabilityRatingOptions,
          permissions,
        ] = await Promise.all([
          fetchOEReportPage({ id: +reportId, year }),
          fetchOERatings({ id: +reportId, year }),
          fetchScenariosFormValues({ id: +reportId, year }),
          fetchOEStatusComments({ id: +reportId, year }),
          fetchOEProbabilityRating({ id: +reportId, year }),
          fetchPermissions(),
        ]);

        app.patchAppState({
          currentOEProbabilityRatingOptions,
          currentOERatings,
          currentOEReportPage,
          currentOEReportPageYear: app.getAppState().year,
          currentOEStatusComments,
          currentScenariosFormValues,
          permissions,
        });
      }
      const oeOverviewPage = await fetchOEOverviewPage(
        yearSelectedPayload.year
      );
      app.patchAppState({
        year: yearSelectedPayload.year,
        oeOverviewPage,
      });
    });

    overlay.on("overlay:toggle-sidebar", () => {
      app.patchAppState({
        isSidebarOpen: !app.getAppState().isSidebarOpen,
      });
    });

    overlay.on("overlay:click-logo", () => {
      app.navigate(`/`);
    });

    overlay.on("overlay:notification-read", (params) => {
      const notificationsPlugin = app.getPluginByName<
        NotificationsPlugin<TracyAppState>
      >("NotificationsPlugin");

      void notificationsPlugin?.handleOverlayNotificationReadEvent(
        params as { payload: DefaultOverlayNotificationPayload }
      );
    });
  }
}
